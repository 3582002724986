<template>
  <div id="app">
    <ul :style="'height:' + ULHeight">
      <li v-for="(item, index) in pageData.list" :key="index" class="list-item">
        <el-card shadow="never" style="margin-top: 5px;" body-style="padding: 0px; margin:0px">
          <div @click="clickToJump(item)">
            <el-row type="flex" justify="space-between" :gutter="10">
              <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1">
                <el-image :src="item.prImg">
                  <div slot="error" class="image-slot" style="border: 1px #EBEEF5 solid;">
                    <span class="imgText">{{ item.prTitle.substring(0, 1) }}</span>
                  </div>
                </el-image>
              </el-col>
              <el-col :xs="16" :lg="21" class="tvDesc marquee">
                <div class="block marquee-wrap">
                  <span class="marquee-content">{{
                      item.prChildId ? item.prTitle + '‖' + item.prChildId : item.prTitle
                    }}</span>
                </div>
                <p>{{ item.prMarkTime }}</p>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </li>
    </ul>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        :pager-count="5"
        :page-size="pageData.pageSize"
        layout="prev, pager, next"
        :total="pageData.total">
    </el-pagination>
  </div>
</template>

<script>
import playRecord from "@/request/playRecord";

export default {
  name: "playRecord",
  data() {
    return {
      ask: new playRecord(),
      pageData: {
        pageNum: 1,
        pageSize: 20
      },
      ULHeight: (window.innerHeight - 16 * 2 - 32 - 44 - 26) + 'px'
    }
  },
  methods: {
    /**
     * 获取历史记录
     */
    getPrList() {
      this.ask.getPlayRecordList({pageNum: 1, pageSize: 20}).then(res => {
        this.pageData = res
      })
    },
    /**
     * 点击播放
     */
    clickToJump(item) {
      this.$router.push({
        name: 'videoPlay',
        query: {
          id: item.prAddress,
          titleName: item.prTitle,
          tvImg: item.prImg,
          spiderId: item.prOrgPlayType,
          playType: item.prType,
        }
      })
    },
    handleCurrentChange(val) {
      this.ask.getPlayRecordList({pageNum: val, pageSize: 20}).then(res => {
        this.pageData = res
      })
    }
  },
  mounted() {
    this.getPrList()
  },
  created() {
    window.onresize = () => {
      this.ULHeight = (window.innerHeight - 16 * 2 - 32 - 44 -26) + 'px'
    };
  }
}
</script>

<style scoped>
#app > div > ul {
  padding-left: 0px;
  overflow-y: scroll;
}

li {
  list-style: none;
}

.el-image {
  width: 100%;
  height: 70px;
}

.el-col {
  text-align: left;
}

.tvDesc > P:nth-child(1) {
  font-weight: bolder;
}

.tvDesc > P:nth-child(2) {
  margin: 50px 0 0 0;
  color: #999999;
}

.imgText {
  font-weight: bolder;
  font-size: 30px;
  line-height: 70px;
  text-align: center;
  padding-left: 35px;
}

.owen {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 17px;
}

.marquee {
  overflow: hidden;
}

.marquee .marquee-wrap {
  width: 100%;
  animation: marquee-wrap 20s infinite linear;
}

.marquee .marquee-content {
  float: left;
  white-space: nowrap;
  min-width: 100%;
  animation: marquee-content 20s infinite linear;
}

@keyframes marquee-wrap {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(100%);
  }
}

@keyframes marquee-content {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(-100%);
  }
}

.el-pagination {
  margin-top: 5px;
  text-align: center;
}
</style>

